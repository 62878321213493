/* Default tooltip theme */
/* TODO Move this into CSS-in-JS via an exported cGS and document its usage */
/* TODO or alternatively use tippyjs/react's Headless API */

.tippy-box[data-theme~='mbui'] .tippy-content {
  font-size: 0.75rem;
}

.tippy-box[data-theme='mbui'] {
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
}

.tippy-box[data-theme='mbui'] .tippy-arrow::before {
  color: rgba(0, 0, 0, 0.75);
}

.tippy-box[data-theme='mbui-white'] {
  background-color: #fff;
  color: #000;
}

.tippy-box[data-theme='mbui-white'] .tippy-arrow::before {
  color: #fff;
}

.tippy-box[data-theme='mbui-black'] {
  background-color: #000;
  color: #fff;
}

.tippy-box[data-theme='mbui-black'] .tippy-arrow::before {
  color: #000;
}

/* ! Don't use these; they serve as a memo/placeholder ! */
.tippy-box[data-theme='mbui-primary'] {
  background-color: #838dff;
  color: #fff;
}

.tippy-box[data-theme='mbui-primary'] .tippy-arrow::before {
  color: #838dff;
}

.tippy-box[data-theme='mbui-danger'] {
  background-color: #ff6161;
  color: #fff;
}

.tippy-box[data-theme='mbui-danger'] .tippy-arrow::before {
  color: #ff6161;
}
